import React from "react";
import PropTypes from "prop-types";
import { Button, SplitDialog, Typography } from "@invosys/invosys-ui";
import {
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  InputAdornment,
} from "@material-ui/core";

const AddressDialog = (props) => {
  const [addressList, setAddressList] = React.useState();
  const [address, setAddress] = React.useState("");
  const [bearer, setBearer] = React.useState("");

  if (!addressList) {
    fetch(
      `${process.env.REACT_APP_POSTCODE_LOOKUP.replace(
        "{postcode}",
        props.postcode.replace(" ", "")
      )}&&type=ll`
    )
      .then((res) => res.json())
      .then((res) => setAddressList(res.addresses || []))
      .catch((e) => {
        console.error(e);
        setAddressList([]);
      });
  }
  const addressListRender = addressList?.length ? (
    <Grid container style={{ width: "80%", margin: "0px auto" }}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          Found following addresses for {props.postcode}
        </Typography>
        <Typography style={{ marginTop: "8px" }}>
          Please select the correct property from the list below
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          select
          onChange={(evt) => {
            const { value } = evt.target;
            setAddress(value);
          }}
          value={address}
        >
          {addressList
            .sort((a, b) => {
              if (a.BuildingNumber !== b.BuildingNumber) {
                return a.BuildingNumber - b.BuildingNumber;
              }

              return `${a.BuildingName || ""} ${a.SubBuilding || ""}` <
                `${b.BuildingName || ""} ${b.SubBuilding || ""}`
                ? -1
                : 1;
            })
            .map((addr, idx) => (
              <MenuItem key={`${addr.ALK}_${idx}`} value={addr}>
                {addr.OrganisationName ? `${addr.OrganisationName}, ` : ""}
                {addr.BuildingName ? `${addr.BuildingName}, ` : ""}
                {addr.SubBuilding ? `${addr.SubBuilding}, ` : ""}
                {addr.BuildingNumber} {addr.Street},{" "}
                {addr.Locality ? `${addr.Locality}, ` : ``}
                {addr.PostTown}, {addr.PostCode}
              </MenuItem>
            ))}
        </TextField>
        <Typography variant={"h6"} style={{ marginTop: "16px" }}>
          Set your bearer and bandwith requirements
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ marginTop: "8px" }}>Bearer Speed</Typography>
        <TextField
          fullWidth
          select
          placeholder={"Bearer"}
          required={true}
          onChange={(evt) => {
            const { value } = evt.target;
            setBearer(value);
            props.updateBearer(value);
            props.updateSpeed(props.speed, value);
          }}
          value={bearer}
        >
          {[100, 1000, 10000].map((b) => (
            <MenuItem key={b} value={b}>
              {b} Mbps
            </MenuItem>
          ))}
        </TextField>
        {bearer && (
          <>
            <Typography style={{ marginTop: "16px" }}>
              Leased Line Bandwidth
            </Typography>
            <TextField
              placeholder={"Desired speed"}
              required={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Mbps</InputAdornment>
                ),
              }}
              value={props.speed}
              onChange={(evt) => {
                props.updateSpeed(evt.target.value, bearer);
              }}
            />
          </>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid container style={{ width: "80%", margin: "20px auto" }}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          No addresses found for {props.postcode}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <SplitDialog
      disableEnforceFocus
      title="Select Address"
      actions={
        addressList?.length
          ? [
              <Button key="close" onClick={() => props.closeMethod()}>
                Cancel
              </Button>,
              <Button
                key="select"
                onClick={() => props.closeMethod(address)}
                variant="contained"
                color="primary"
                disabled={!props.speed || !address}
              >
                Submit
              </Button>,
            ]
          : [
              <Button key="close" onClick={() => props.closeMethod()}>
                Close
              </Button>,
            ]
      }
      dialogContent={
        addressList ? (
          addressListRender
        ) : (
          <Grid container style={{ width: "80%", margin: "20px auto" }}>
            <Grid item xs={12}>
              <Typography variant={"h6"}>
                Fetching list of addresses for {props.postcode}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress style={{ width: "100%" }} />
            </Grid>
          </Grid>
        )
      }
      fullWidth
      maxWidth={"sm"}
    />
  );
};

AddressDialog.propTypes = {
  postcode: PropTypes.string,
  closeMethod: PropTypes.func,
  setAddressMethod: PropTypes.func,
  updateBearer: PropTypes.func,
  updateSpeed: PropTypes.func,
  bearer: PropTypes.number,
  speed: PropTypes.string,
};
export default AddressDialog;
