import React from "react";
import PropTypes from "prop-types";
import { Grid, Menu, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";

const headerStyle = {
  header: {
    marginRight: "22px",
    color: "white",
  },
};

class ButtonAppBarCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handleMenu = this.handleMenu.bind(this);
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <Grid container alignItems="center">
          <Typography style={headerStyle.header}>
            {this.props.displayName || ""}
          </Typography>
          <IconButton onClick={this.handleMenu}>
            <div>
              <Avatar alt="" src="/static/images/avatar/1.jpg" />
            </div>
          </IconButton>
        </Grid>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
          getContentAnchorEl={null}
        >
          {this.props.children}
        </Menu>
      </div>
    );
  }
}

ButtonAppBarCollapse.propTypes = {
  children: PropTypes.node,
  displayName: PropTypes.string,
};
export default ButtonAppBarCollapse;
