import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Typography } from "@material-ui/core";

const AddressDetails = (props) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ padding: "6px 0 12px 0", textAlign: "center" }}
      >
        <Paper className="flat-23" elevation={23} square>
          <Typography variant="h6">Selected Address Details</Typography>
        </Paper>
      </Grid>
      <Grid item xs={2} style={{ paddingLeft: "8px" }}>
        <Typography style={{ fontWeight: "bold" }}>Address : </Typography>
      </Grid>
      <Grid item xs={4}>
        {props.address?.OrganisationName ? (
          <Typography>{props.address.OrganisationName}</Typography>
        ) : null}
        {props.address?.BuildingName ? (
          <Typography>{props.address.BuildingName}</Typography>
        ) : null}
        {props.address?.SubBuilding ? (
          <Typography>{props.address.SubBuilding}</Typography>
        ) : null}
        <Typography>
          {props.address?.BuildingNumber} {props.address?.Street}
        </Typography>
        {props.address?.Locality ? (
          <Typography>{props.address.Locality}</Typography>
        ) : null}
        <Typography>{props.address?.PostTown}</Typography>
        <Typography>{props.address?.PostCode}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ fontWeight: "bold" }}>Address Key : </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{props.address?.ALK}</Typography>
      </Grid>
    </Grid>
  );
};

AddressDetails.propTypes = { address: PropTypes.object };
export default AddressDetails;
