/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Button, Layout, SplitDialog, Typography } from "@invosys/invosys-ui";
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddressDialog from "./AddressDialog";
import AddressDetails from "./AddressDetails";
import HelpSearchHeader from "../../shared/HelpSearchHeader";
import validateToken from "../../shared/Helpers";

// const ttbProducts = require("../../data/ttb.json");

export default class LeasedLines extends React.Component {
  constructor(props) {
    super();
    this.pollTimeout = null;
    props.setMainState({
      filters: {
        supplier: [],
        term: [],
      },
    });
  }

  searchMethod = (value) => {
    console.debug("Looking up addresses", value);
    this.props.setMainState({ searchPostcode: value, showAddressDialog: true });
  };

  updateSpeed = (value, bearerValue) => {
    if (
      value === "" ||
      (`${Number(value)}` === value &&
        Number(value) > 0 &&
        Number(value) <= bearerValue)
    )
      this.props.setMainState({ speed: value, bearer: bearerValue });
    else if (Number(value) > bearerValue)
      this.props.setMainState({ speed: bearerValue, bearer: bearerValue });
  };

  updateBearer = (value) => {
    console.log(value);
    if (
      value === "" ||
      (`${Number(value)}` === value &&
        Number(value) > 0 &&
        Number(value) <= 10000)
    )
      this.props.setMainState({ bearer: value });
  };

  submitSearch = (e) => {
    if (e.key === "Enter") {
      this.closeDialog(this.props.getState().address);
    }
  };

  submitButtonSearch = () => {
    if (
      Number(this.props.getState().speed) > 0 &&
      Number(this.props.getState().speed) <= 10000
    ) {
      this.closeDialog(this.props.getState().address);
    }
  };

  isAuthenticated = () => validateToken();

  componentDidMount = () => {
    const addressString = localStorage.getItem("ll.address");
    const speedString = localStorage.getItem("ll.speed");
    const bearerString = localStorage.getItem("ll.bearer");
    if (addressString) {
      const address = JSON.parse(addressString);
      this.props.setMainState(
        { address, speed: Number(speedString), bearer: Number(bearerString) },
        () => {
          this.closeDialog(address);
          localStorage.removeItem("ll.address");
          localStorage.removeItem("ll.speed");
          localStorage.removeItem("ll.bearer");
        }
      );
    }
    // // eslint-disable-next-line no-undef, no-restricted-globals
    // document.querySelector(
    //   ".cognito-wrapper"
    // ).innerHTML = `<div class="cognito" style="overflow-y:scroll; overflow-x: hidden; height:calc(100vh - 250px);width:50vw">

    // </div>`;
    // // eslint-disable-next-line no-undef, no-restricted-globals
    // top.api.mount("49", ".cognito");
  };

  pollResults = () => {
    let baseUrl = process.env.REACT_APP_PRODUCT_POLL;
    const options = { method: "get" };
    if (this.isAuthenticated()) {
      baseUrl = process.env.REACT_APP_SECURE_PRODUCT_POLL;
      options.headers = {
        authorization: localStorage.getItem("fttp.authToken"),
      };
    }
    fetch(
      `${baseUrl.substring(0, baseUrl.indexOf("?"))}?requestId=${
        this.props.getState().requestId
      }`,
      options
    )
      .then((res) => res.json())
      .then((res) => {
        this.props.setMainState(
          {
            loadingProducts: res.status !== "complete",
            productList: res.availableProducts,
          },
          () => {
            if (res.status !== "complete") {
              window.setTimeout(this.pollResults, 2500);
            }
          }
        );
      })
      .catch((e) => {
        console.error(e);
        this.props.setMainState({ productList: [], loadingProducts: false });
      });
  };

  closeDialog = (address) => {
    if (address) {
      this.props.setMainState(
        (prevState) => ({
          showAddressDialog: false,
          address,
          loadingProducts: true,
          productList: [],
          searchSpeed: prevState.speed,
          searchBearer: prevState.bearer,
        }),
        () => {
          if (address.Uprn) {
            const options = { method: "get" };
            if (this.isAuthenticated()) {
              options.headers = {
                authorization: localStorage.getItem("fttp.authToken"),
              };
            }
            fetch(
              process.env.REACT_APP_LEASED_PRODUCT_LOOKUP.replace(
                "{postcode}",
                address.PostCode
              )
                .replace("{uprn}", address.Uprn)
                .replace("{lat}", address.Latitude)
                .replace("{lon}", address.Longitude)
                .replace("{speed}", this.props.getState().speed)
                .replace("{bearer}", this.props.getState().bearer),
              options
            )
              .then((res) => res.json())
              .then((res) => {
                this.props.setMainState(res);
                window.setTimeout(this.pollResults, 100);
              })
              .catch((e) => {
                console.error(e);
                this.props.setMainState({
                  productList: [],
                  loadingProducts: false,
                });
              });
          } else {
            this.props.setMainState({
              productList: [],
              loadingProducts: false,
            });
          }
        }
      );
    } else {
      this.props.setMainState({ showAddressDialog: false });
    }
  };

  showOrder = () => {
    if (document) {
      document.querySelector(".cognito-popup").style.display = "";
    }
  };

  render = () => {
    const { filters } = this.props.getState() || {};
    const filteredResults = (this.props.getState().productList || [])
      .filter((o) => o != null)
      .filter(
        (prod) =>
          (filters.supplier.length === 0 ||
            filters.supplier.includes(prod.supplier)) &&
          (filters.term.length === 0 || filters.term.includes(prod.term))
      );
    return (
      <Layout
        styles={{
          marginTop: "80px",
          height: "calc(100vh - 120px)",
          backgroundColor: "transparent",
        }}
        navigation={this.props.navigation}
        navigationComponent={this.props.navigationComponent}
        location={this.props.location}
        theme={this.props.theme}
        searchHeader={
          <HelpSearchHeader
            searchLabel="Postcode"
            placeholder="Enter postcode here"
            searchMethod={this.searchMethod}
            helpText={
              <div style={{ padding: "2em 2em 1em 2em", marginTop: "1em" }}>
                <Typography>
                  All Leased Line products are quoted for your specific
                  requirements, allowing you to request fibre services from the
                  supplier, direct to your premises. The pricing considers your
                  required speed and length of term, and will be tailored
                  according to this.
                  <br /> <br />{" "}
                </Typography>
                {this.isAuthenticated() && (
                  <Typography>
                    To place your connectivity order please select the
                    &quot;Place Order&quot; button to complete the order form,
                    once the form has been submitted our order desk will be in
                    contact with you with regular order updates.
                  </Typography>
                )}
              </div>
            }
            filters={this.props.getState().filters || {}}
            handleFilter={(newFilters) => {
              this.props.setMainState({ filters: newFilters });
            }}
            availableProducts={this.props.getState().productList}
            useFilters={["supplier", "term"]}
          />
        }
      >
        {this.props.getState().showAddressDialog && (
          <AddressDialog
            closeMethod={this.closeDialog}
            postcode={this.props.getState().searchPostcode}
            updateBearer={this.updateBearer}
            updateSpeed={this.updateSpeed}
            speed={this.props.getState().speed || ""}
            bearer={this.props.getState().bearer || ""}
          />
        )}
        {this.props.getState().address ? (
          <AddressDetails
            address={this.props.getState().address}
            bearer={this.props.getState().bearer}
            speed={this.props.getState().speed}
            updateBearer={this.updateBearer}
            updateSpeed={this.updateSpeed}
            submitSearch={this.submitSearch}
            submitButtonSearch={this.submitButtonSearch}
            searchSpeed={this.props.getState().searchSpeed}
            searchBearer={this.props.getState().searchBearer}
          />
        ) : (
          <Typography
            style={{ margin: "48px auto", width: "50%", textAlign: "center" }}
            variant="h6"
          >
            Please enter a postcode and select the correct address to see the
            available products
          </Typography>
        )}
        {this.props.getState().productList &&
        this.props.getState().productList.length ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", marginBottom: "12px" }}
                >
                  Available products for the selected property
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  textAlign: "right",
                  padding: "0 16px",
                }}
              >
                {!this.isAuthenticated() && (
                  <Button
                    onClick={() =>
                      this.props.setMainState({ showNextStepDialog: true })
                    }
                    color="primary"
                    variant="contained"
                    style={{ width: "12em" }}
                  >
                    View Pricing
                  </Button>
                )}
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Supplier
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Product Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Product Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Speed (Mbps)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Bearer (Mbps)
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Term (Years)
                    </Typography>
                  </TableCell>
                  {this.isAuthenticated() && (
                    <TableCell>
                      <Typography style={{ fontWeight: "bold" }}>
                        Install Cost (£)
                      </Typography>
                    </TableCell>
                  )}
                  {this.isAuthenticated() && (
                    <TableCell>
                      <Typography style={{ fontWeight: "bold" }}>
                        Monthly Cost (£)
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredResults
                  .sort((a, b) =>
                    a.monthlyfee - b.monthlyfee === 0
                      ? a.setupfee - b.setupfee
                      : a.monthlyfee - b.monthlyfee
                  )
                  .map((product, index) => {
                    const { address } = this.props.getState();
                    const orderObject = {
                      ResellerCompanyName: this.props.userInfo.customer,
                      ResellerEmailAddress: this.props.userInfo.email,
                      SiteDetails: {
                        FTTPInstallationAddress: {
                          Line1: `${
                            address.BuildingName
                              ? address.SubBuilding
                                ? `${address.BuildingName} (${address.SubBuilding}), `
                                : `${address.BuildingName}, `
                              : ``
                          } ${address.BuildingNumber} ${address.Street}`,
                          Line2: address.Locality,
                          City: address.PostTown,
                          PostalCode: address.PostCode,
                        },
                      },
                      ProductDetails: {
                        Bearer: `${
                          `${product.bearer}` === "100"
                            ? "100 Mbps"
                            : `${product.bearer}` === "1000"
                            ? "1 Gbps"
                            : "10 Gbps"
                        }`,
                        Bandwidth: `${product.speed / 1000} Gbps`,
                        Bandwidth2: `${product.speed} Mbps`,
                        Bandwidth3: `${product.speed} Mbps`,
                        Term: `${product.term} Year${
                          product.Term === "1" ? "" : "s"
                        }`,
                        Supplier: product.supplier,
                        PleaseSelectTheStartOfTheProductNameFromTheConnectivityChecker:
                          product.productName.substring(0, 7),
                      },
                    };

                    console.log(product, orderObject);

                    return (
                      <TableRow
                        key={index}
                        className={`product_${index % 2 ? "odd" : "even"}`}
                      >
                        <TableCell>
                          <Typography>{product.supplier}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.productName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{`${product.productType}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.speed}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.bearer}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.term}</Typography>
                        </TableCell>
                        {this.isAuthenticated() && (
                          <TableCell>
                            <Typography>
                              {Number.isNaN(product.setupfee)
                                ? product.setupfee.toFixed(2)
                                : product.setupfee}
                            </Typography>
                          </TableCell>
                        )}
                        {this.isAuthenticated() && (
                          <TableCell>
                            <Typography>
                              {Number.isNaN(product.monthlyfee)
                                ? product.monthlyfee.toFixed(2)
                                : product.monthlyfee}
                            </Typography>
                          </TableCell>
                        )}
                        {this.isAuthenticated() && (
                          <TableCell>
                            <Button
                              href={`https://www.cognitoforms.com/InvosysLtd1/WholesaleLeasedLineOrderingForm?entry=${JSON.stringify(
                                orderObject
                              )}`}
                              target="_blank"
                              color="primary"
                              variant="contained"
                              style={{ width: "12em" }}
                            >
                              Place Order
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {this.props.getState().loadingProducts &&
                  this.props.getState().productList &&
                  this.props.getState().productList.length && (
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center" }}>
                        <Typography>Looking for more Products</Typography>
                      </TableCell>
                      <TableCell colSpan={this.isAuthenticated() ? 4 : 3}>
                        <div style={{ width: "80%", margin: "auto" }}>
                          <LinearProgress
                            color="primary"
                            style={{ height: "1rem" }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </React.Fragment>
        ) : null}
        {this.props.getState().loadingProducts &&
          !(
            this.props.getState().productList &&
            this.props.getState().productList.length
          ) && (
            <React.Fragment>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Fetching available product options
              </Typography>
              <div style={{ width: "72px", margin: "48px auto" }}>
                <CircularProgress size={72} />
              </div>
            </React.Fragment>
          )}

        {!this.props.getState().loadingProducts &&
        this.props.getState().productList &&
        !this.props.getState().productList.length ? (
          <React.Fragment>
            <Typography variant="h6" style={{ textAlign: "center" }}>
              No products found for the selected property
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              This list is continually changing so please check back again soon.
            </Typography>
          </React.Fragment>
        ) : null}
        {this.props.getState().showNextStepDialog && (
          <SplitDialog
            dialogContent={
              <React.Fragment>
                <Typography>
                  To view pricing details and to place an order please click the
                  button to login
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      localStorage.setItem(
                        "ll.address",
                        JSON.stringify(this.props.getState().address)
                      );
                      localStorage.setItem(
                        "ll.speed",
                        this.props.getState().speed
                      );
                      localStorage.setItem(
                        "ll.bearer",
                        this.props.getState().bearer
                      );
                      this.props.setMainState(
                        { showNextStepDialog: false },
                        () => this.props.showLogin()
                      );
                    }}
                    variant="contained"
                    color="primary"
                    style={{ margin: "1.5em auto", width: "25%" }}
                  >
                    Login
                  </Button>
                </div>
                <Typography>
                  Or if you want to contact us please{" "}
                  <a
                    href="https://www.invosys.com/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    click here
                  </a>{" "}
                  to visit our website (a new window will be opened)
                </Typography>
              </React.Fragment>
            }
            actions={[
              <Button
                key="close"
                onClick={() =>
                  this.props.setMainState({ showNextStepDialog: false })
                }
              >
                Close
              </Button>,
            ]}
          />
        )}
      </Layout>
    );
  };
}

LeasedLines.propTypes = {
  theme: PropTypes.any,
  showLogin: PropTypes.func,
  navigation: PropTypes.array,
  navigationComponent: PropTypes.any,
  location: PropTypes.object,
  close: PropTypes.func,
  setMainState: PropTypes.func,
  getState: PropTypes.func,
  userInfo: PropTypes.object,
};
