const getValueFromResults = (resultDetails, param) => {
  return (resultDetails || []).find(
    (detail) => detail.key === param && (detail.value || detail.value === 0)
  )?.value;
};

export const resultHasValue = (resultDetails, param) => {
  return !!(resultDetails || []).find(
    (detail) => detail.key === param && (detail.value || detail.value === 0)
  );
};

export const convertQuery = (queryString) => {
  if (queryString) {
    return queryString
      .substring(1)
      .split("&")
      .map((entry) => ({
        [entry.split("=")[0]]:
          entry.split("=").length === 2 ? entry.split("=")[1] : true,
      }))
      .reduce((a = {}, entry) => ({ ...a, ...entry }));
  }
  return {};
};

export default getValueFromResults;
