/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Avatar, Chip, Menu, MenuItem, Paper } from "@material-ui/core";

import { Button } from "@invosys/invosys-ui";
import { ControlPoint } from "@material-ui/icons";

// import { stateMap } from "../../utils/constants";

const Filters = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuName, setMenuName] = React.useState(null);

  const addFilter = (category) => (value) => {
    props.addFilter({ category, value });
    setMenuName("");
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setMenuName(event.currentTarget.name);
    setAnchorEl(event.currentTarget);
  };

  return (
    <Paper elevation={23} style={props.style}>
      <div className="filter-selection" style={{ textAlign: "center" }}>
        {/* Supplier */}
        {props.useFilters.includes("supplier") && (
          <React.Fragment>
            <Button
              aria-controls="supplierMenu"
              aria-haspopup="true"
              name="supplier"
              onClick={handleClick}
            >
              <ControlPoint style={{ color: "white" }} />
              <span style={{ color: "white" }}>Supplier</span>
            </Button>
            <Menu
              id="supplierMenu"
              style={{ maxHeight: "45vh" }}
              anchorEl={anchorEl}
              keepMounted
              open={menuName === "supplier"}
              onClose={() => {
                setMenuName("");
                setAnchorEl(null);
              }}
            >
              {[
                ...new Set(
                  props.products
                    .map((product) => [
                      JSON.stringify({
                        value: product.supplier,
                        type: "supplier",
                      }),
                    ])
                    .reduce((acc = [], val) => [...acc, ...val])
                ),
              ]
                .map((str) => JSON.parse(str))
                .sort((a, b) => (a.value < b.value ? -1 : 1))
                .map((mapValue) => (
                  <MenuItem
                    key={mapValue.value}
                    value={mapValue.value}
                    onClick={() => addFilter("supplier")(mapValue.value)}
                  >
                    {mapValue.value}
                  </MenuItem>
                ))}
            </Menu>
          </React.Fragment>
        )}
        {/* Product Name */}
        {props.useFilters.includes("name") && (
          <React.Fragment>
            <Button
              aria-controls="productNameMenu"
              aria-haspopup="true"
              name="productName"
              onClick={handleClick}
            >
              <ControlPoint style={{ color: "white" }} />
              <span style={{ color: "white" }}>Name</span>
            </Button>
            <Menu
              id="productNameMenu"
              style={{ maxHeight: "45vh" }}
              anchorEl={anchorEl}
              keepMounted
              open={menuName === "productName"}
              onClose={() => {
                setMenuName("");
                setAnchorEl(null);
              }}
            >
              {[
                ...new Set(
                  props.products
                    .map((product) => [
                      JSON.stringify({
                        value: product.displayName,
                        type: "name",
                      }),
                    ])
                    .reduce((acc = [], val) => [...acc, ...val])
                ),
              ]
                .map((str) => JSON.parse(str))
                .sort((a, b) => {
                  const sortA = (a.value || "").split(" ");
                  const sortB = (b.value || "").split(" ");

                  return (
                    Number(sortA[sortA.length - 1]) -
                    Number(sortB[sortB.length - 1])
                  );
                })
                .map((mapValue) => (
                  <MenuItem
                    key={mapValue.value}
                    value={mapValue.value}
                    onClick={() => addFilter("name")(mapValue.value)}
                  >
                    {mapValue.value}
                  </MenuItem>
                ))}
            </Menu>
          </React.Fragment>
        )}
        {/* Product Type */}
        {props.useFilters.includes("type") && (
          <React.Fragment>
            <Button
              aria-controls="productTypeMenu"
              aria-haspopup="true"
              name="productType"
              onClick={handleClick}
            >
              <ControlPoint style={{ color: "white" }} />
              <span style={{ color: "white" }}>Type</span>
            </Button>
            <Menu
              id="productTypeMenu"
              style={{ maxHeight: "45vh" }}
              anchorEl={anchorEl}
              keepMounted
              open={menuName === "productType"}
              onClose={() => {
                setMenuName("");
                setAnchorEl(null);
              }}
            >
              {[
                ...new Set(
                  props.products
                    .map((product) => [
                      JSON.stringify({ value: product.type, type: "type" }),
                    ])
                    .reduce((acc = [], val) => [...acc, ...val])
                ),
              ]
                .map((str) => JSON.parse(str))
                .sort((a, b) => (a.value < b.value ? -1 : 1))
                .map((mapValue) => (
                  <MenuItem
                    key={mapValue.value}
                    value={mapValue.value}
                    onClick={() => addFilter("type")(mapValue.value)}
                  >
                    {mapValue.value}
                  </MenuItem>
                ))}
            </Menu>
          </React.Fragment>
        )}
        {/* Product Term */}
        {props.useFilters.includes("term") && (
          <React.Fragment>
            <Button
              aria-controls="productTermMenu"
              aria-haspopup="true"
              name="productTerm"
              onClick={handleClick}
            >
              <ControlPoint style={{ color: "white" }} />
              <span style={{ color: "white" }}>Term</span>
            </Button>
            <Menu
              id="productTermMenu"
              style={{ maxHeight: "45vh" }}
              anchorEl={anchorEl}
              keepMounted
              open={menuName === "productTerm"}
              onClose={() => {
                setMenuName("");
                setAnchorEl(null);
              }}
            >
              {[
                ...new Set(
                  props.products
                    .map((product) => [
                      JSON.stringify({ value: product.term, type: "term" }),
                    ])
                    .reduce((acc = [], val) => [...acc, ...val])
                ),
              ]
                .map((str) => JSON.parse(str))
                .sort((a, b) => (a.value < b.value ? -1 : 1))
                .map((mapValue) => (
                  <MenuItem
                    key={mapValue.value}
                    value={mapValue.value}
                    onClick={() => addFilter("term")(mapValue.value)}
                  >
                    {mapValue.value}
                  </MenuItem>
                ))}
            </Menu>
          </React.Fragment>
        )}
      </div>
      <div className="activeFilters" style={{ textAlign: "center" }}>
        {Object.keys(props.filterList).map((key) =>
          props.filterList[key].map((value) => (
            <Chip
              key={`${key}${value}`}
              style={{ margin: "8px" }}
              avatar={<Avatar>{key.substring(0, 1).toUpperCase()}</Avatar>}
              label={value}
              onDelete={() => props.removeFilter({ category: key, value })}
            />
          ))
        )}
      </div>
    </Paper>
  );
};

Filters.propTypes = {
  style: PropTypes.object,
  filterList: PropTypes.object,
  products: PropTypes.array,
  addFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  useFilters: PropTypes.array,
};
export default Filters;
