import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  TextField,
  MenuItem,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { Button } from "@invosys/invosys-ui";

const AddressDetails = (props) => {
  const [bearer, setBearer] = React.useState("");

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ padding: "6px 0 12px 0", textAlign: "center" }}
      >
        <Paper className="flat-23" elevation={23} square>
          <Typography variant="h6">Selected Address Details</Typography>
        </Paper>
      </Grid>
      <Grid item xs={2} style={{ paddingLeft: "8px" }}>
        <Typography style={{ fontWeight: "bold" }}>Address : </Typography>
      </Grid>
      <Grid item xs={4}>
        {props.address?.OrganisationName ? (
          <Typography>{props.address.OrganisationName}</Typography>
        ) : null}
        {props.address?.BuildingName ? (
          <Typography>{props.address.BuildingName}</Typography>
        ) : null}
        {props.address?.SubBuilding ? (
          <Typography>{props.address.SubBuilding}</Typography>
        ) : null}
        <Typography>
          {props.address?.BuildingNumber} {props.address?.Street}
        </Typography>
        {props.address?.Locality ? (
          <Typography>{props.address.Locality}</Typography>
        ) : null}
        <Typography>{props.address?.PostTown}</Typography>
        <Typography>{props.address?.PostCode}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid container xs={8}>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                Required Bearer:
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "-4px" }}>
              <TextField
                select
                placeholder={"Bearer"}
                required={true}
                onChange={(evt) => {
                  const { value } = evt.target;
                  setBearer(value);
                  props.updateBearer(value);
                  props.updateSpeed(props.speed, value);
                }}
                value={bearer}
              >
                {[100, 1000, 10000].map((b) => (
                  <MenuItem key={b} value={b}>
                    {b} Mbps
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                Required Speed:
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "-4px" }}>
              <TextField
                style={{ width: "8em" }}
                value={props.speed}
                onChange={(evt) => {
                  props.updateSpeed(evt.target.value, bearer);
                }}
                onKeyPress={props.submitSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Mbps</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              textAlign: "right",
              paddingRight: "16px",
            }}
          >
            <Button
              key="update"
              variant="contained"
              color="primary"
              onClick={props.submitButtonSearch}
              disabled={props.searchSpeed === props.speed}
              style={{ width: "12em" }}
            >
              Update Speed and Bearer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddressDetails.propTypes = {
  address: PropTypes.object,
  bearer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateBearer: PropTypes.func,
  speed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateSpeed: PropTypes.func,
  submitSearch: PropTypes.func,
  submitButtonSearch: PropTypes.func,
  searchSpeed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchBearer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default AddressDetails;
