import React from "react";
import PropTypes from "prop-types";
import { Button, SplitDialog } from "@invosys/invosys-ui";
import { Typography } from "@material-ui/core";

const EstimatedCostDialog = (props) => {
  return (
    <SplitDialog
      open={!!props.show}
      title={"Additional Costs"}
      actions={[
        <Button
          key="close"
          onClick={props.closeMethod}
          variant={props.infoOnly ? "outlined" : "text"}
        >
          Close
        </Button>,
        props.link && !props.infoOnly ? (
          <Button
            key="continue"
            href={props.link}
            onClick={props.closeMethod}
            target="_blank"
            rel="noreferrer"
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        ) : null,
      ]}
      dialogContent={
        <Typography>
          This installation may include ECC (Excess Construction Charges),
          estimated at &quot;£{props.estimatedEcc}&quot;. This is an early
          estimate based on simple distance from the network. This is subject to
          survey after an order is placed, once the survey has taken place an
          accurate cost can be provided and a decision made whether to continue
          with the order or not.
        </Typography>
      }
    />
  );
};

EstimatedCostDialog.propTypes = {
  link: PropTypes.string,
  estimatedEcc: PropTypes.string,
  closeMethod: PropTypes.func,
  show: PropTypes.bool,
  infoOnly: PropTypes.bool,
};
export default EstimatedCostDialog;
