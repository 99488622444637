import React from "react";
import PropTypes from "prop-types";
import { Button, SearchHeader } from "@invosys/invosys-ui";
import { FilterList, Info, InfoOutlined } from "@material-ui/icons";
import {
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Filters from "./Filters";

const HelpSearchHeader = (props) => {
  const { helpText, ...otherProps } = props;
  const [showHelp, setShowHelp] = React.useState(props.defaultShowHelp);
  const [showFilters, setShowFilters] = React.useState(false);

  const handleFilterSearch = (type) => (data) => {
    const { filters } = props;
    if (type === "add")
      if (filters[data.category]) {
        filters[data.category] = Array.from(
          new Set([...filters[data.category], data.value]).values()
        );
      } else {
        filters[data.category] = [data.value];
      }
    if (type === "remove") {
      filters[data.category] = [
        ...filters[data.category].filter((val) => val !== data.value),
      ];
    }
    props.handleFilter(filters);
  };

  return (
    <React.Fragment>
      <SearchHeader
        {...otherProps}
        actions={
          <React.Fragment>
            <Grid container>
              <Typography style={{ margin: "16px 8px 0 0" }}>
                Filters
              </Typography>
              <IconButton
                style={{
                  cursor: "pointer",
                  margin: "4px 0px 0 0",
                }}
                onClick={() => {
                  setShowFilters(!showFilters);
                  props.handleFilter({
                    supplier: [],
                    name: [],
                    type: [],
                    term: [],
                  });
                }}
                disabled={
                  !props.availableProducts || !props.availableProducts.length
                }
              >
                <FilterList color={showFilters ? "primary" : "secondary"} />
              </IconButton>

              <Tooltip
                title={showHelp ? "Collapse" : "Show Further Information"}
                arrow={true}
              >
                <IconButton
                  onClick={() => {
                    setShowHelp(!showHelp);
                    if (props.hideHelpHandler) {
                      props.hideHelpHandler();
                    }
                  }}
                  style={{ marginTop: "4px" }}
                >
                  {showHelp ? <Info /> : <InfoOutlined />}
                </IconButton>
              </Tooltip>
            </Grid>
          </React.Fragment>
        }
      />
      <Collapse in={showHelp}>
        {helpText}
        <Button
          style={{ marginLeft: "2em" }}
          color="secondary"
          variant="contained"
          onClick={() => {
            setShowHelp(false);
            if (props.hideHelpHandler) {
              props.hideHelpHandler();
            }
          }}
        >
          Close
        </Button>
      </Collapse>
      {showFilters && (
        <Filters
          style={{ margin: "8px -8px -16px -8px" }}
          filterList={props.filters}
          addFilter={handleFilterSearch("add")}
          removeFilter={handleFilterSearch("remove")}
          products={props.availableProducts || []}
          useFilters={props.useFilters}
        />
      )}
      {/* </Collapse> */}
    </React.Fragment>
  );
};

HelpSearchHeader.propTypes = {
  helpText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  defaultShowHelp: PropTypes.bool,
  filters: PropTypes.object,
  availableProducts: PropTypes.array,
  handleFilter: PropTypes.func,
  hideHelpHandler: PropTypes.func,
  useFilters: PropTypes.array,
};
export default HelpSearchHeader;
