import { Button, Grid } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React from "react";
import theme from "./styles/theme";

export default class SubmitApp extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render = () => {
    return (
      <MuiThemeProvider theme={createTheme(theme)}>
        <Grid
          container
          justifyContent="flex-end"
          style={{
            width: "50vw",
            maxWidth: "848px",
            margin: "0 auto",
            backgroundColor: "#fefefe",
            padding: "10px",
          }}
        >
          <Button
            onClick={() => {
              if (document) {
                document.querySelector(".cognito-popup").style.display = "none";
              }
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (document && document.querySelector(".cog-button--submit")) {
                console.log("Submitting");
                document.querySelector(".cog-button--submit").click();
              }
            }}
          >
            Submit
          </Button>
        </Grid>
      </MuiThemeProvider>
    );
  };
}

SubmitApp.propTypes = {};
