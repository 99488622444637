import React from "react";
import PropTypes from "prop-types";
import { Button, SplitDialog, Typography } from "@invosys/invosys-ui";
import { Grid, LinearProgress, MenuItem, TextField } from "@material-ui/core";

const AddressDialog = (props) => {
  const [addressList, setAddressList] = React.useState();
  const [address, setAddress] = React.useState("");

  if (!addressList) {
    fetch(
      process.env.REACT_APP_POSTCODE_LOOKUP.replace(
        "{postcode}",
        props.postcode.replace(" ", "")
      )
    )
      .then((res) => res.json())
      .then((res) => setAddressList(res.addresses || []))
      .catch((e) => {
        console.error(e);
        setAddressList([]);
      });
  }
  const addressListRender = addressList?.length ? (
    <Grid container style={{ width: "80%", margin: "0px auto" }}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          Found following addresses for {props.postcode}
        </Typography>
        <Typography>
          Please select the correct property from the list below
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          style={{ marginTop: "16px" }}
          fullWidth
          select
          onChange={(evt) => {
            const { value } = evt.target;
            setAddress(value);
          }}
          value={address}
        >
          {addressList
            .sort((a, b) => {
              if (a.BuildingNumber !== b.BuildingNumber) {
                return a.BuildingNumber - b.BuildingNumber;
              }

              return `${a.BuildingName || ""} ${a.SubBuilding || ""}` <
                `${b.BuildingName || ""} ${b.SubBuilding || ""}`
                ? -1
                : 1;
            })
            .map((addr, idx) => (
              <MenuItem key={`${addr.ALK}_${idx}`} value={addr}>
                {addr.OrganisationName ? `${addr.OrganisationName}, ` : ""}
                {addr.BuildingName ? `${addr.BuildingName}, ` : ""}
                {addr.SubBuilding ? `${addr.SubBuilding}, ` : ""}
                {addr.BuildingNumber} {addr.Street},{" "}
                {addr.Locality ? `${addr.Locality}, ` : ``}
                {addr.PostTown}, {addr.PostCode}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    </Grid>
  ) : (
    <Grid container style={{ width: "80%", margin: "20px auto" }}>
      <Grid item xs={12}>
        <Typography variant={"h6"}>
          No addresses found for {props.postcode}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <SplitDialog
      disableEnforceFocus
      title="Select Address"
      open={true}
      actions={
        addressList?.length
          ? [
              <Button key="close" onClick={() => props.closeMethod()}>
                Cancel
              </Button>,
              <Button
                key="select"
                onClick={() => props.closeMethod(address)}
                variant="contained"
                color="primary"
              >
                Select
              </Button>,
            ]
          : [
              <Button key="close" onClick={() => props.closeMethod()}>
                Close
              </Button>,
            ]
      }
      dialogContent={
        addressList ? (
          addressListRender
        ) : (
          <Grid container style={{ width: "80%", margin: "20px auto" }}>
            <Grid item xs={12}>
              <Typography variant={"h6"}>
                Fetching list of addresses for {props.postcode}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LinearProgress style={{ width: "100%" }} />
            </Grid>
          </Grid>
        )
      }
      fullWidth
      maxWidth={"sm"}
    />
  );
};

AddressDialog.propTypes = {
  postcode: PropTypes.string,
  closeMethod: PropTypes.func,
  setAddressMethod: PropTypes.func,
};
export default AddressDialog;
