import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { createHash, randomBytes } from "crypto";

import { convertQuery } from "../utils/helpers";

function useQuery() {
  return convertQuery(useLocation().search);
}

const LOGIN_URL = `${window.location.protocol}//${window.location.host}/login`;

const LoginApp = (props) => {
  const { REACT_APP_OAUTH_CLIENT_ID, REACT_APP_OAUTH_URL } = process.env;
  const query = useQuery();
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [redirecting, setRedirecting] = React.useState(false);

  const base64url = (buffer) => {
    return buffer
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  };

  useEffect(() => {
    const getToken = async () => {
      const { code } = query;
      if (!code && props.login) {
        const cookieToken = localStorage.getItem("fttp.authToken");
        if (!cookieToken) {
          if (!redirecting) {
            setRedirecting(true);
            const codeVerifier = base64url(randomBytes(32));
            const codeChallenge = base64url(
              createHash("sha256").update(codeVerifier).digest()
            );
            sessionStorage.setItem("code_verifier", codeVerifier);
            window.location.href = `${REACT_APP_OAUTH_URL}/oauth2/authorize?response_type=code&client_id=${REACT_APP_OAUTH_CLIENT_ID}&redirect_uri=${LOGIN_URL}&code_challenge_method=S256&code_challenge=${codeChallenge}`;
          }
        }
      } else {
        const codeVerifier = sessionStorage.getItem("code_verifier");
        if (!loggingIn && codeVerifier) {
          setLoggingIn(true);
          await fetch(
            `${REACT_APP_OAUTH_URL}/oauth2/token?grant_type=authorization_code&client_id=${REACT_APP_OAUTH_CLIENT_ID}&code_verifier=${codeVerifier}&redirect_uri=${LOGIN_URL}&code=${query.code}`,
            {
              method: "post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              if (data.id_token) {
                props.getToken(data.id_token, data.refresh_token);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    };
    getToken();
  }, [
    query,
    REACT_APP_OAUTH_CLIENT_ID,
    REACT_APP_OAUTH_URL,
    props,
    loggingIn,
    redirecting,
  ]);

  return <></>;
};

LoginApp.propTypes = {
  getToken: PropTypes.func,
  login: PropTypes.bool,
};

export default LoginApp;
