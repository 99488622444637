import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Paper } from "@material-ui/core";
import { FttpIcon } from "@invosys/invosys-ui/icons";
import { SettingsInputComposite } from "@material-ui/icons";
import theme, { greyTheme } from "./styles/theme";
import Header from "./shared/Header";
import FttpChecker from "./pages/FttpChecker/FttpChecker";
import LeasedLines from "./pages/LeasedLines/LeasedLines";
import LoginApp from "./shared/Login";
import RouteChangeTracker from "./shared/RouteChangeTracker/RouteChangeTracker";
import validateToken from "./shared/Helpers";

require("./App.css");

const TRACKING_ID = process.env.REACT_APP_GA_TAG;

ReactGA.initialize(TRACKING_ID);

class App extends Component {
  constructor() {
    super();
    this.state = { login: false, fttp: {}, leasedline: {} };
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  getState = (objectName) => () => {
    return this.state[objectName];
  };

  setMainState =
    (objectName) =>
    (newStateObj, callback = () => {}) => {
      this.setState(
        (prevState) => ({
          [objectName]: {
            ...prevState[objectName],
            ...(newStateObj instanceof Function
              ? newStateObj(prevState[objectName])
              : newStateObj),
          },
        }),
        callback
      );
    };

  componentDidMount = () => {
    const userToken = localStorage.getItem("fttp.authToken");
    console.log("Referrer", document.referrer);
    if (userToken && validateToken()) {
      fetch(`${process.env.REACT_APP_API_PREFIX}/getcustomerdetails`, {
        method: "get",
        headers: { authorization: userToken },
      })
        .then((res) => res.json())
        .then((res) => this.setState({ userInfo: res }))
        .catch(() => {
          this.setState({ userInfo: {} });
        });

      fetch(`${process.env.REACT_APP_API_PREFIX}/getcustomerbranding`, {
        method: "get",
        headers: { authorization: userToken },
      })
        .then((res) => res.json())
        .then((res) => {
          const themeObject = { ...theme };
          let updated = false;
          if (res.background) {
            themeObject.palette.background.default = res.background;
            updated = true;
          }
          if (res.primary) {
            themeObject.palette.primary.main = res.primary;
            themeObject.overrides.MuiPaper.elevation23.backgroundColor =
              res.primary;
            updated = true;
          }
          if (res.secondaryText) {
            themeObject.palette.primary.contrastText = res.secondaryText;
            updated = true;
          }
          if (res.secondary) {
            themeObject.palette.secondary.main = res.secondary;
            themeObject.overrides.MuiPaper.outlined.backgroundColor =
              res.secondary;
            updated = true;
          }
          if (res.primarytext) {
            themeObject.overrides.MuiPaper.elevation23.color = res.primarytext;
            updated = true;
          }
          if (updated) {
            this.setState({
              userBranding: res,
              customTheme: createTheme(themeObject),
            });
          } else {
            this.setState({
              userBranding: {},
              customTheme: createTheme(theme),
            });
          }
        })
        .catch(() => {
          this.setState({
            userBranding: {},
            customTheme: createTheme(theme),
          });
        });
    } else {
      this.setState({
        userBranding: {},
        customTheme: createTheme(theme),
      });
    }
  };

  getToken = (data, refreshToken) => {
    localStorage.setItem("fttp.authToken", data);
    localStorage.setItem("fttp.refreshToken", refreshToken);
    window.location.href = "/";
  };

  render() {
    const navigationElements = [
      {
        label: "SoGEA/FTTP",
        link: "/fttpchecker",
        icon: <FttpIcon color="primary" />,
      },
      {
        label: "Leased Line",
        link: "/leasedline",
        icon: <SettingsInputComposite color="primary" />,
      },
    ];
    return (
      <MuiThemeProvider
        theme={this.state.customTheme || createTheme(greyTheme)}
      >
        <RouteChangeTracker />
        {(this.props?.location?.search || "").indexOf("code=") < 0 && (
          <Paper elevation={0} style={{ backgroundColor: "transparent" }}>
            <CssBaseline />
            <Header
              branding={this.state.userBranding || {}}
              userInfo={this.state.userInfo || {}}
              loading={!this.state.customTheme}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                paddingRight: "5px",
                opacity: 0.5,
              }}
            >
              {process.env.REACT_APP_GIT_VERSION}
            </div>
            <Switch>
              <Route
                path="/fttpchecker"
                render={() => (
                  <FttpChecker
                    theme={this.state.customTheme || createTheme(greyTheme)}
                    showLogin={() => this.setState({ login: true })}
                    navigation={navigationElements}
                    navigationComponent={Link}
                    location={this.props.location}
                    getState={this.getState("fttp")}
                    setMainState={this.setMainState("fttp")}
                    userInfo={this.state.userInfo || {}}
                  />
                )}
              />
              <Route
                path="/leasedline"
                render={() => (
                  <LeasedLines
                    theme={this.state.customTheme || createTheme(greyTheme)}
                    showLogin={() => this.setState({ login: true })}
                    navigation={navigationElements}
                    navigationComponent={Link}
                    location={this.props.location}
                    getState={this.getState("leasedline")}
                    setMainState={this.setMainState("leasedline")}
                    userInfo={this.state.userInfo || {}}
                  />
                )}
              />
              <Redirect
                exact
                from="/"
                to={
                  localStorage.getItem("ll.speed")
                    ? "/leasedline"
                    : "/fttpchecker"
                }
              />
              <Redirect
                exact
                from="/logout"
                to={
                  localStorage.getItem("ll.speed")
                    ? "/leasedline"
                    : "/fttpchecker"
                }
              />
            </Switch>
          </Paper>
        )}
        <LoginApp
          getToken={this.getToken}
          login={
            this.state.login ||
            document.referrer.startsWith(process.env.REACT_APP_CLOUDKEEPER_URL)
          }
        />
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
};

export default withRouter(App);
