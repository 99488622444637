/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Button, Layout, SplitDialog, Typography } from "@invosys/invosys-ui";
import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import {
  ToggleArrowDownIcon,
  ToggleArrowUpIcon,
  ToggleBothIcon,
} from "@invosys/invosys-ui/icons";
import AddressDialog from "./AddressDialog";
import AddressDetails from "./AddressDetails";
import HelpSearchHeader from "../../shared/HelpSearchHeader";
import validateToken from "../../shared/Helpers";
import EstimatedCostDialog from "./EstimatedCostDialog";

// const ttbProducts = require("../../data/ttb.json");

export default class FttpChecker extends React.Component {
  constructor(props) {
    super();
    this.pollTimeout = null;
    props.setMainState({
      filters: { supplier: [], name: [], type: [] },
    });
    this.state = { pollCount: 0 };
  }

  searchMethod = (value) => {
    console.debug("Looking up addresses", value);
    this.props.setMainState({ searchPostcode: value, showAddressDialog: true });
  };

  isAuthenticated = () => validateToken();

  componentDidMount = () => {
    const addressString = localStorage.getItem("fttp.address");
    if (addressString) {
      const address = JSON.parse(addressString);
      this.props.setMainState({ address }, () => {
        this.closeDialog(address);
        localStorage.removeItem("fttp.address");
      });
    }
    this.setState({
      sortField: this.isAuthenticated() ? "price" : "download",
      sortOrder: "asc",
    });
  };

  pollResults = () => {
    let baseUrl = process.env.REACT_APP_PRODUCT_POLL;
    const options = { method: "get" };
    if (this.isAuthenticated()) {
      baseUrl = process.env.REACT_APP_SECURE_PRODUCT_POLL;
      options.headers = {
        authorization: localStorage.getItem("fttp.authToken"),
      };
    }
    const { address } = this.props.getState();

    fetch(
      `${baseUrl
        .replace("{galk}", address.ALK)
        .replace("{districtCode}", address.CSSDistrictCode)}&requestId=${
        this.props.getState().requestId
      }`,
      options
    )
      .then((res) => res.json())
      .then((res) => {
        this.props.setMainState(
          {
            loadingProducts: res.status !== "complete",
            productList: res.availableProducts,
          },
          () => {
            if (res.status !== "complete" && this.state.pollCount < 24) {
              this.pollTimeout = window.setTimeout(this.pollResults, 2500);
              this.setState((prevState) => ({
                pollCount: prevState.pollCount + 1,
              }));
            } else {
              this.props.setMainState({ loadingProducts: false }, () => {
                this.setState({ pollCount: 0 });
              });
            }
          }
        );
      })
      .catch((e) => {
        console.error(e);
        this.props.setMainState({ productList: [], loadingProducts: false });
      });
  };

  closeDialog = (address) => {
    if (address) {
      this.setState({ pollCount: 0 });
      window.clearTimeout(this.pollTimeout);
      this.props.setMainState(
        {
          showAddressDialog: false,
          address,
          loadingProducts: true,
          productList: [],
        },
        () => {
          if (address) {
            const options = { method: "get" };
            if (this.isAuthenticated()) {
              options.headers = {
                authorization: localStorage.getItem("fttp.authToken"),
              };
            }
            const call = process.env.REACT_APP_PRODUCT_LOOKUP.replace(
              "{galk}",
              address.ALK ? address.ALK : false
            )
              .replace(
                "{districtCode}",
                address.CSSDistrictCode ? address.CSSDistrictCode : false
              )
              .replace("{postcode}", address.PostCode)
              .replace("{uprn}", address.Uprn)
              .replace("{lat}", address.Latitude)
              .replace("{lon}", address.Longitude);
            fetch(call, options)
              .then((res) => res.json())
              .then((res) => {
                this.props.setMainState(res);
                this.pollTimeout = window.setTimeout(this.pollResults, 100);
              })
              .catch((e) => {
                console.error(e);
                this.props.setMainState({
                  productList: [],
                  loadingProducts: false,
                });
              });
          } else {
            this.props.setMainState({
              productList: [],
              loadingProducts: false,
            });
          }
        }
      );
    } else {
      this.props.setMainState({ showAddressDialog: false });
    }
  };

  showOrder = () => {
    if (document) {
      document.querySelector(".cognito-popup").style.display = "";
    }
  };

  priceSort = (a, b) => {
    const priceA = Number.isNaN(a.price) ? a.price.toFixed(2) : a.price;
    const priceB = Number.isNaN(b.price) ? b.price.toFixed(2) : b.price;

    return this.state.sortOrder === "asc" ? priceA - priceB : priceB - priceA;
  };

  nameSort = (a, b) => {
    if (a.displayName === b.displayName) {
      return 0;
    }
    if (this.state.sortOrder === "asc")
      return a.displayName > b.displayName ? 1 : -1;

    return a.displayName < b.displayName ? 1 : -1;
  };

  supplierSort = (a, b) => {
    const supplierA = `${a.supplier}${a.provider ? ` (${a.provider})` : ``}`;
    const supplierB = `${b.supplier}${b.provider ? ` (${b.provider})` : ``}`;
    if (supplierA === supplierB) {
      return 0;
    }
    if (this.state.sortOrder === "asc") return supplierA > supplierB ? 1 : -1;

    return supplierA < supplierB ? 1 : -1;
  };

  downloadSort = (a, b) => {
    let downloadA = a.download;
    let downloadB = b.download;
    if (a.downloadRange) {
      downloadA = parseInt(`${a.downloadRange} - `.split(" - ")[0], 10);
    }

    if (b.downloadRange) {
      downloadB = parseInt(`${b.downloadRange} - `.split(" - ")[0], 10);
    }
    return this.state.sortOrder === "asc"
      ? downloadA - downloadB
      : downloadB - downloadA;
  };

  render = () => {
    const { filters, helpHidden } = this.props.getState();
    const filteredResults = (this.props.getState().productList || [])
      .filter((o) => o != null)
      .filter(
        (prod) =>
          (filters.supplier.length === 0 ||
            filters.supplier.includes(prod.supplier)) &&
          (filters.name.length === 0 ||
            filters.name.includes(prod.displayName)) &&
          (filters.type.length === 0 || filters.type.includes(prod.type))
      );
    return (
      <Layout
        styles={{
          marginTop: "80px",
          height: "calc(100vh - 120px)",
          backgroundColor: "transparent",
        }}
        navigation={this.props.navigation}
        navigationComponent={this.props.navigationComponent}
        location={this.props.location}
        theme={this.props.theme}
        searchHeader={
          <HelpSearchHeader
            searchLabel="Postcode"
            placeholder="Enter postcode here"
            searchMethod={this.searchMethod}
            defaultShowHelp={helpHidden}
            hideHelpHandler={() => {
              this.props.setMainState({ helpHidden: true });
            }}
            helpText={
              <div style={{ padding: "2em 2em 1em 2em", marginTop: "1em" }}>
                <Typography>
                  The Invosys connectivity checker will search for available
                  SoGEA and FTTP services through our various supplier partners,
                  allowing you to choose the best option for you and your
                  customer.
                </Typography>
                <br />
                <Typography>
                  All of our connectivity products are provided with a
                  complimentary, pre-configured router compatible with the
                  product ordered, which is also added to the Invosys ACS
                  platform where you can monitor and remotely manage the device.
                </Typography>
                <br />
                <Typography>
                  Where the estimated speeds show the maximum line speed, this
                  is an up-to-speed and is not guaranteed.
                </Typography>
                <br />
              </div>
            }
            filters={this.props.getState().filters || {}}
            handleFilter={(newFilters) => {
              this.props.setMainState({ filters: newFilters });
            }}
            availableProducts={this.props.getState().productList}
            useFilters={["supplier", "name", "type"]}
          />
        }
      >
        {this.props.getState().showAddressDialog && (
          <AddressDialog
            closeMethod={this.closeDialog}
            postcode={this.props.getState().searchPostcode}
          />
        )}
        {this.props.getState().address ? (
          <AddressDetails address={this.props.getState().address} />
        ) : (
          <Typography
            style={{ margin: "48px auto", width: "50%", textAlign: "center" }}
            variant="h6"
          >
            Please enter a postcode and select the correct address to see the
            available products
          </Typography>
        )}
        {this.props.getState().productList &&
        this.props.getState().productList.length ? (
          <React.Fragment>
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center", marginBottom: "12px" }}
                >
                  Available products for the selected property
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  textAlign: "right",
                  padding: "0 16px",
                }}
              >
                {!this.isAuthenticated() && (
                  <Button
                    onClick={() =>
                      this.props.setMainState({ showNextStepDialog: true })
                    }
                    color="primary"
                    variant="contained"
                  >
                    View Pricing
                  </Button>
                )}
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid container alignItems="center">
                      <Typography style={{ fontWeight: "bold" }}>
                        Supplier
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() =>
                          this.setState((prevState) => ({
                            sortField: "supplier",
                            sortOrder:
                              prevState.sortField !== "supplier" ||
                              prevState.sortOrder !== "asc"
                                ? "asc"
                                : "desc",
                          }))
                        }
                      >
                        {this.state.sortField === "supplier" ? (
                          this.state.sortOrder === "asc" ? (
                            <ToggleArrowDownIcon htmlColor="#000" />
                          ) : (
                            <ToggleArrowUpIcon htmlColor="#000" />
                          )
                        ) : (
                          <ToggleBothIcon />
                        )}
                      </IconButton>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container alignItems="center">
                      <Typography style={{ fontWeight: "bold" }}>
                        Product Name
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() =>
                          this.setState((prevState) => ({
                            sortField: "name",
                            sortOrder:
                              prevState.sortField !== "name" ||
                              prevState.sortOrder !== "asc"
                                ? "asc"
                                : "desc",
                          }))
                        }
                      >
                        {this.state.sortField === "name" ? (
                          this.state.sortOrder === "asc" ? (
                            <ToggleArrowDownIcon htmlColor="#000" />
                          ) : (
                            <ToggleArrowUpIcon htmlColor="#000" />
                          )
                        ) : (
                          <ToggleBothIcon />
                        )}
                      </IconButton>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid
                      container
                      alignItems="center"
                      style={{ minHeight: "30px" }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Product Type
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Estimated Download <br />
                      Speeds(Mbps)
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography style={{ fontWeight: "bold" }}>
                      Estimated Upload <br />
                      Speeds(Mbps)
                    </Typography>
                  </TableCell>
                  {this.isAuthenticated() && (
                    <React.Fragment>
                      <TableCell>
                        <Grid container alignItems="center">
                          <Typography style={{ fontWeight: "bold" }}>
                            Monthly Cost (£)
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={() =>
                              this.setState((prevState) => ({
                                sortField: "price",
                                sortOrder:
                                  prevState.sortField !== "price" ||
                                  prevState.sortOrder !== "asc"
                                    ? "asc"
                                    : "desc",
                              }))
                            }
                          >
                            {this.state.sortField === "price" ? (
                              this.state.sortOrder === "asc" ? (
                                <ToggleArrowDownIcon htmlColor="#000" />
                              ) : (
                                <ToggleArrowUpIcon htmlColor="#000" />
                              )
                            ) : (
                              <ToggleBothIcon />
                            )}
                          </IconButton>
                        </Grid>
                      </TableCell>
                      <TableCell />
                    </React.Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredResults
                  .sort((a, b) => {
                    let sort = 0;
                    switch (this.state.sortField) {
                      case "supplier":
                        sort = this.supplierSort(a, b);
                        break;
                      case "price":
                        sort = this.priceSort(a, b);
                        break;
                      case "name":
                        sort = this.nameSort(a, b);
                        break;
                      default:
                        break;
                    }
                    if (sort === 0) {
                      return this.downloadSort(a, b);
                    }
                    return sort;
                  })
                  .map((product, index) => {
                    const { address } = this.props.getState();
                    let productName = "FibreProductSpeed";
                    if (product.supplier === "Gamma")
                      productName = "FibreProductSpeed2";

                    if (product.supplier === "Vodafone")
                      productName = "FibreProductSpeed3";
                    if (product.supplier === "ITS")
                      productName = "FibreProductSpeed4";
                    if (product.supplier === "DWS") {
                      productName =
                        `${product.provider}`.toLowerCase() === "bt"
                          ? "FibreProductSpeed6"
                          : "FibreProductSpeed5";
                    }
                    let speedField = "";
                    switch (product.supplier) {
                      case "Vodafone":
                        speedField = "SoGEAProductSpeed2";
                        break;
                      case "Giacom":
                        speedField =
                          `${product.provider}`.toLowerCase() === "bt"
                            ? "SoGEAProductSpeed4"
                            : "SoGEAProductSpeed3";
                        break;
                      default:
                        speedField = "SoGEAProductSpeed";
                        break;
                    }

                    const productTypeDetails =
                      product.type === "FTTP"
                        ? {
                            Supplier: `${product.supplier}${
                              product.provider ? ` (${product.provider})` : ``
                            }`,
                            [productName]: product.displayName,
                          }
                        : {
                            Supplier: `${product.supplier}${
                              product.provider ? ` (${product.provider})` : ``
                            }`,
                            [speedField]: product.displayName.replace(":", "/"),
                          };

                    const orderObject = {
                      PartnerDetails: {
                        ResellerCompanyName: this.props.userInfo.customer,
                        ResellerEmailAddress: this.props.userInfo.email,
                      },
                      InstallationDetails: {
                        InstallationAddress: {
                          Line1: `${
                            address.BuildingName
                              ? address.SubBuilding
                                ? `${address.BuildingName} (${address.SubBuilding}), `
                                : `${address.BuildingName}, `
                              : ``
                          } ${address.BuildingNumber} ${address.Street}`,
                          Line2: address.Locality,
                          City: address.PostTown,
                          PostalCode: address.PostCode,
                        },
                      },
                      ProductDetails: {
                        SelectWhichConnectivityYouWouldLikeToOrder:
                          product.type,
                        [product.type]: productTypeDetails,
                      },
                    };
                    return (
                      <TableRow
                        key={index}
                        className={`product_${index % 2 ? "odd" : "even"}`}
                      >
                        <TableCell>
                          <Typography>{`${product.supplier} ${
                            product.provider ? `(${product.provider})` : ``
                          }`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{product.displayName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{`${product.type}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {product.downloadRange || product.download}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {product.uploadRange || product.upload}
                          </Typography>
                        </TableCell>
                        {this.isAuthenticated() && (
                          <React.Fragment>
                            <TableCell>
                              <Typography>
                                {Number.isNaN(product.price)
                                  ? product.price.toFixed(2)
                                  : product.price}
                                {product.estimated_ecc && (
                                  <IconButton
                                    size="small"
                                    style={{ margin: "-2px 0 2px" }}
                                    onClick={() =>
                                      this.setState({
                                        selectedProduct: product,
                                        showEstimationDialog: true,
                                        productLink: `https://www.cognitoforms.com/InvosysLtd1/WholesaleConnectivityOrderForm2?entry=${JSON.stringify(
                                          orderObject
                                        )}`,
                                        infoOnly: true,
                                      })
                                    }
                                  >
                                    <InfoOutlined
                                      color="primary"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {product.estimated_ecc ? (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() =>
                                    this.setState({
                                      selectedProduct: product,
                                      showEstimationDialog: true,
                                      productLink: `https://www.cognitoforms.com/InvosysLtd1/WholesaleConnectivityOrderForm2?entry=${JSON.stringify(
                                        orderObject
                                      )}`,
                                    })
                                  }
                                >
                                  Place Order
                                </Button>
                              ) : (
                                <Button
                                  href={`https://www.cognitoforms.com/InvosysLtd1/WholesaleConnectivityOrderForm2?entry=${JSON.stringify(
                                    orderObject
                                  )}`}
                                  target="_blank"
                                  color="primary"
                                  variant="contained"
                                >
                                  Place Order
                                </Button>
                              )}
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    );
                  })}
                {this.props.getState().loadingProducts &&
                  this.props.getState().productList &&
                  this.props.getState().productList.length && (
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center" }}>
                        <Typography>Looking for more Products</Typography>
                      </TableCell>
                      <TableCell colSpan={this.isAuthenticated() ? 4 : 3}>
                        <div style={{ width: "80%", margin: "auto" }}>
                          <LinearProgress
                            color="primary"
                            style={{ height: "1rem" }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </React.Fragment>
        ) : null}
        {this.props.getState().loadingProducts &&
          !(
            this.props.getState().productList &&
            this.props.getState().productList.length
          ) && (
            <React.Fragment>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Fetching available product options
              </Typography>
              <div style={{ width: "72px", margin: "48px auto" }}>
                <CircularProgress size={72} />
              </div>
            </React.Fragment>
          )}

        {!this.props.getState().loadingProducts &&
        this.props.getState().productList &&
        !this.props.getState().productList.length ? (
          <React.Fragment>
            <Typography variant="h6" style={{ textAlign: "center" }}>
              No products found for the selected property
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              This list is continually changing so please check back again soon.
            </Typography>
          </React.Fragment>
        ) : null}
        {this.props.getState().showNextStepDialog && (
          <SplitDialog
            dialogContent={
              <React.Fragment>
                <Typography>
                  To view pricing details and to place an order please click the
                  button to login
                </Typography>
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      localStorage.setItem(
                        "fttp.address",
                        JSON.stringify(this.props.getState().address)
                      );
                      this.props.setMainState(
                        { showNextStepDialog: false },
                        () => this.props.showLogin()
                      );
                    }}
                    variant="contained"
                    color="primary"
                    style={{ margin: "1.5em auto", width: "25%" }}
                  >
                    Login
                  </Button>
                </div>
                <Typography>
                  Or if you want to contact us please{" "}
                  <a
                    href="https://www.invosys.com/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    click here
                  </a>{" "}
                  to visit our website (a new window will be opened)
                </Typography>
              </React.Fragment>
            }
            actions={[
              <Button
                key="close"
                onClick={() =>
                  this.props.setMainState({ showNextStepDialog: false })
                }
              >
                Close
              </Button>,
            ]}
          />
        )}
        <EstimatedCostDialog
          show={this.state.showEstimationDialog}
          estimatedEcc={this.state.selectedProduct?.estimated_ecc}
          link={this.state.productLink}
          infoOnly={this.state.infoOnly}
          closeMethod={() =>
            this.setState({
              showEstimationDialog: false,
              selectedProduct: null,
              productLink: "",
              infoOnly: false,
            })
          }
        />
      </Layout>
    );
  };
}

FttpChecker.propTypes = {
  theme: PropTypes.any,
  showLogin: PropTypes.func,
  navigation: PropTypes.array,
  navigationComponent: PropTypes.any,
  location: PropTypes.object,
  setMainState: PropTypes.func,
  getState: PropTypes.func,
  userInfo: PropTypes.object,
};
