export default {
  palette: {
    background: {
      default: "#acadac",
    },
    primary: {
      main: "#ce3030",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0d1728",
      contrastText: "#eee",
    },
    error: {
      main: "#d0021b",
    },
  },
  overrides: {
    MuiCollapse: {
      entered: {
        overflow: "auto",
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: "top",
      },
    },
    MuiPaper: {
      outlined: {
        backgroundColor: "#0d1728",
      },
      elevation22: {
        boxShadow: "none",
        borderBottom: "1px solid",
        borderRadius: "0",
        borderColor: "#0d1728",
        padding: "8px",
      },
      elevation23: {
        boxShadow: "none",
        backgroundColor: "#ce3030",
        color: "white",
      },
    },
  },
};

const darkTheme = {
  palette: {
    type: "dark",
    primary: {
      main: "#e64210",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2C2F30",
    },
    error: {
      main: "#FF4444",
    },
    background: {
      paper: "#2c2f30",
    },
  },
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        overflow: "auto",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "2px solid rgba(255,255,255,0.7)",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "rgb(255,255,255)",
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: "top",
      },
    },
  },
};

export { darkTheme };

export const greyTheme = {
  palette: {
    primary: {
      main: "#333",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ccc",
    },
    error: {
      main: "#FF4444",
    },
    background: {
      paper: "#fff",
    },
  },
  overrides: {
    MuiExpansionPanelDetails: {
      root: {
        overflow: "auto",
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "2px solid rgba(33,33,33,0.8)",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#333",
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: "top",
      },
    },
  },
};
