import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";

function getBaseAddress() {
  return `${window.location.protocol}//${window.location.host}`;
}
function AppBarCollapse(props) {
  const {
    REACT_APP_OAUTH_URL,
    REACT_APP_OAUTH_CLIENT_ID,
    REACT_APP_CLOUDKEEPER_URL,
  } = process.env;
  function removeStore() {
    localStorage.removeItem("fttp.authToken");
    window.location.href = `${REACT_APP_OAUTH_URL}/logout?client_id=${REACT_APP_OAUTH_CLIENT_ID}&logout_uri=${getBaseAddress()}/logout`;
  }
  if (localStorage.getItem("fttp.authToken")) {
    return (
      <div>
        <ButtonAppBarCollapse displayName={props.userInfo.customer || ""}>
          <MenuItem>Hello {props.userInfo.name || ""}</MenuItem>
          <MenuItem>
            <a href={REACT_APP_CLOUDKEEPER_URL}>Return To Cloudkeeper</a>
          </MenuItem>
          <MenuItem onClick={removeStore}>Log out</MenuItem>
        </ButtonAppBarCollapse>
      </div>
    );
  }
  return <div></div>;
}

AppBarCollapse.propTypes = {
  userInfo: PropTypes.object,
};
export default AppBarCollapse;
