import jwtDecode from "jwt-decode";

let refreshTimeout = null;

const validateToken = () => {
  const { REACT_APP_OAUTH_CLIENT_ID, REACT_APP_OAUTH_URL } = process.env;
  if (localStorage.getItem("fttp.authToken")) {
    try {
      const tokenDetail = jwtDecode(localStorage.getItem("fttp.authToken"));
      if (tokenDetail.exp * 1000 < new Date().getTime()) {
        console.debug("Token expired");
        throw new Error("expired");
      }
      console.debug("Token valid");
      if (localStorage.getItem("fttp.refreshToken")) {
        if (refreshTimeout) {
          window.clearTimeout(refreshTimeout);
        }
        refreshTimeout = window.setTimeout(() => {
          fetch(
            `${REACT_APP_OAUTH_URL}/oauth2/token?grant_type=refresh_token&client_id=${REACT_APP_OAUTH_CLIENT_ID}&refresh_token=${localStorage.getItem(
              "fttp.refreshToken"
            )}`,
            {
              method: "post",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              if (data.id_token) {
                localStorage.setItem("fttp.authToken", data.id_token);
              }
              const newTokenDetail = jwtDecode(
                localStorage.getItem("fttp.authToken")
              );
              refreshTimeout = window.setTimeout(
                this.getToken,
                newTokenDetail.exp * 1000 - new Date().getTime()
              );
            })
            .catch((error) => {
              console.error(error);
              this.getToken();
            });
        }, Math.floor((tokenDetail.exp + 60) * 1000 - new Date().getTime()));
      }
      return true;
    } catch (e) {
      localStorage.removeItem("fttp.authToken");
      return false;
    }
  }
  return false;
};

export default validateToken;
